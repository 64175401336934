import Loading from 'components/global/layout/Loading';
import React from 'react';
import Leaderboard from './Leaderboard';
import { useGetLeaderboardQuery } from './leaderboardSlice';

const Index = () => {
  const { isLoading, data, isSuccess, refetch,isFetching } = useGetLeaderboardQuery();
  return (
    <div className='min-h-[50vh]'>
      {isLoading && <Loading />}
      {isSuccess && data && (
        <div>
          {data.length === 0 ? (
            'No scores available yet.'
          ) : (
            <div className='flex gap-8'>
              <Leaderboard
                data={data}
                refetch={refetch}
                isFetching={isFetching}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Index;
