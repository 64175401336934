import classNames from 'classnames';
import { useMemo } from 'react';
import ApplicationSettings from './layout/ApplicationSettings';
import BelongScoreCard from './layout/BelongScoreCard';
import { useGetSummaryQuery } from './panelSlics';
import {
  checkIfTestInProgress,
  getAptitudeUrl,
  getDomainScore,
} from './utils/helper';
import AptitudeScoreCard from './layout/AptitudeScoreCard';
// import DomainScoreCard from './layout/DomainScoreCard';
// import ProfileScoreCard from './layout/ProfileScoreCard';
// import SelectDomainPopUp from './layout/SelectDomainPopUp';
// import { customStyles } from '../../profile/ModalStyles';
// import Modal from 'react-modal';
// import { useGetUserQuery } from '../../profile/userSlice';
// import { AiOutlineDown } from 'react-icons/ai';

const Index = () => {
  // const [currentDomain, setCurrentDomain] = useState({});
  const { isLoading, data } = useGetSummaryQuery({});
  // const { data: userData } = useGetUserQuery();

  const aptitudeData = data?.aptitude;
  const domainData = data?.domain;
  const aptitudeUrlLink = getAptitudeUrl(data);
  // const domainUrlLink = getDoaminUrl(data);
  const incompleteTest = checkIfTestInProgress(data);
  const aptitudeScore = aptitudeData?.bestAttempt?.percentage;

  const domainScore = useMemo(
    () => getDomainScore(domainData?.bestAttempt?.percentage),
    [domainData?.bestAttempt?.percentage]
  );
  const domainScoreRoundedToOne = Math.round(domainScore?.score * 10) / 10;
  const aptitudeScoreRoundedToOne = Math.round(aptitudeScore * 10) / 10;

  const belongScore = aptitudeScoreRoundedToOne + domainScoreRoundedToOne;

  //To Do: Handle error
  // const [modalIsOpen, setIsOpen] = useState(false);
  // function openModal() {
  //   setIsOpen(true);
  // }
  // function closeModal() {
  //   setIsOpen(false);
  // }
  // const [selectedDomainName, setSelectedDomainName] = useState(
  //   userData?.areas[0]?.title
  // );

  return (
    <div className='grid grid-cols-12 gap-4'>
      <div className='col-span-12 lg:col-span-10'>
        {/* {Boolean(data?.domain?.totalAttempts) && (
          <div onClick={() => openModal()} className='flex justify-end'>
            <button className='px-2 py-1 rounded flex items-center gap-x-1 border-2 border-primary-light'>
              {selectedDomainName || userData?.areas[0]?.title}
              <AiOutlineDown />
            </button>
          </div>
        )} */}
        <div className='shadow-lg p-2 sm:p-8'>
          <div
            className={classNames(
              ' gap-4 lg:gap-6 grid grid-cols-2 lg:grid-cols-4',
              {
                'animate-pulse pointer-events-none': isLoading,
              }
            )}
          >
            <BelongScoreCard
              aptitudeScore={aptitudeScoreRoundedToOne}
              score={belongScore}
              url={aptitudeUrlLink}
              incompleteTest={incompleteTest}
            />
            {/* Aptitude */}
            <AptitudeScoreCard
              url={aptitudeUrlLink}
              aptitudeScore={aptitudeScoreRoundedToOne}
              gradiantColor='to-bgIndigo'
              heading='Aptitude Score'
              maxScore={100}
              userScore={0}
              unlocktext='Unlock Opportunities at 25/40'
            />
            {/* Domain */}
            {/* <DomainScoreCard
              url={domainUrlLink}
              domainScore={domainScoreRoundedToOne}
              gradiantColor='to-bgCyan'
              heading='Domain Score'
              maxScore={50}
              userScore={0}
              unlocktext='Unlock Opportunities at 35/50'
            /> */}
            {/* Profile */}
            {/* <ProfileScoreCard
              gradiantColor='to-bgPink'
              heading='Profile Strength'
              maxScore={10}
              userScore={data?.profileStrength || 0}
              unlocktext='Unlock Opportunities at 5/10'
            /> */}
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-12 mt-12 space-y-4 md:space-y-0'>
            <ApplicationSettings />
          </div>
        </div>
      </div>
      {/* <div className='col-span-3 bg-gray-50 grid place-items-center '></div> */}
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Example Modal'
        data={{ background: 'gray' }}
      >
        <div>
          <SelectDomainPopUp
            closeModal={closeModal}
            setCurrentDomain={setCurrentDomain}
            setSelectedDomainName={setSelectedDomainName}
            currentDomain={currentDomain}
          />
        </div>
      </Modal> */}
    </div>
  );
};

export default Index;
