import { useGetUserQuery } from './userSlice';
import BasicDetails from './BasicDetails';
import FullDetails from './FullDetails';
import BelongScore from './belongScore';
import ProfileStrength from './profileStrengthAndSkill/ProfileStrength';
import Loading from 'components/global/layout/Loading';
import ProfileStrengthCard from './profileStrengthAndSkill/ProfileStrengthCard';

const Index = () => {
  const { isLoading, isSuccess, isError, data } = useGetUserQuery();
  const user = data;
  return (
    <>
      <div>{isLoading && <Loading />}</div>
      <div>{isError && 'Error'}</div>
      {isSuccess && (
        <div className='p-1'>
          <img
            className='w-full hidden sm:block'
            alt='bg'
            src='https://res.cloudinary.com/belong/image/upload/v1661164758/b2c/profile_lftimu.png'
          />
          <div className='sm:hidden mb-6'>
            <ProfileStrengthCard />
          </div>
          <div className='grid grid-cols-5 sm:gap-2'>
            <div className='col-span-5 sm:col-span-1'>
              <BasicDetails
                firstName={user.firstName}
                lastName={user.lastName}
                latestCertification={user.latestCertification}
                currentRole={user.currentRole}
                imageUrl={user.imageUrl}
              />
            </div>
            <div className='col-span-5 sm:col-span-3'>
              <FullDetails user={user} />
            </div>
            <div className='col-span-5 sm:col-span-1'>
              <ProfileStrength />
            </div>
          </div>
          <div className='border-t-2 border-gray-500 mx-6'>
            <BelongScore />
          </div>
        </div>
      )}
    </>
  );
};

export default Index;

// interface IscProps {
//   gradiantColor: string;
//   heading: string;
//   maxScore: number;
//   userScore: number;
//   unlocktext: string;
// }

// const IndividualScoreCard: React.FC<IscProps> = (props: IscProps) => {
