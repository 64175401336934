import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLeaderboard: builder.query<any, void>({
      query: () => {
        return {
          url: '/user/profile/artevelde-leaderboard',
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['Leaderboard'],
    }),
  }),
});

export const { useGetLeaderboardQuery } = extendedApiSlice;
