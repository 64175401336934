import classNames from 'classnames';
import { AiOutlineReload } from 'react-icons/ai';

const Leaderboard = ({
  data,
  refetch,
  isFetching,
}: {
  data: any;
  refetch: any;
  isFetching: boolean;
}) => {
  return (
    <div className=' py-4 rounded w-96 mx-auto'>
      <div className='flex justify-around gap-4 mb-6'>
        <p className='text-textColor-lightest font-medium text-center  '>
          Score Leaderboard
        </p>
        <div>
          <button
            onClick={() => {
              refetch();
            }}
            type='button'
            className='inline-flex items-center gap-x-1.5 rounded-md bg-primary-light px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-iprimary-lighter focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-light'
          >
            <AiOutlineReload className='-ml-0.5 h-5 w-5' aria-hidden='true' />
            {isFetching ? 'Fetching' : 'Refresh'}
          </button>
        </div>
      </div>

      <div className='space-y-4'>
        {data.map((a: any, index: number) => {
          const user = a.userId;
          return (
            <div
              className={classNames(
                'text-white  px-4 py-2 flex gap-2 shadow font-medium rounded-full justify-between',
                {
                  'bg-textColor-lighter ': index <= 2,
                },
                {
                  'bg-[#1D436FCC]': index > 2,
                }
              )}
              key={a._id}
            >
              <p className='w-1/5 text-left'> # {index + 1}</p>
              <p className='shrink-0'>
                {`${user.firstName ? user.firstName[0].toUpperCase() : ''}${
                  user.firstName ? user.firstName?.slice(1).toLowerCase() : ''
                }`}{' '}
                {`${user.lastName ? user.lastName[0]?.toUpperCase() : ''}${
                  user.lastName ? user?.lastName?.slice(1)?.toLowerCase() : ''
                }`}
              </p>
              <p className='w-8 h-8 flex items-center justify-center rounded-full bg-primary-light'>
                {Math.ceil(a.percentage) || '-'}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Leaderboard;
